<template>
	<div class="grid">
		<div class="col-12">
			<Card>
                <template #title>
                    {{title}}
                </template>
                <template #content>
					<!-- Toolbar -->
					<Toolbar class="mb-4">
						<template #start>
							<Button v-if="$can('page-add')" @click="$router.push('/dashboard/page-new')" label="Tambah" icon="pi pi-plus" class="p-button-primary mr-2" />
							<Button v-if="$can('page-delete')" label="Hapus" icon="pi pi-trash" class="p-button-danger" 
								@click="openDelete('multi')" :disabled="!dataTable.selectedData || !dataTable.selectedData.length" />
						</template>
					</Toolbar>

					<!-- Data Table -->
					<DataTable class="p-datatable-gridlines p-datatable-sm" filterDisplay="menu" :rowHover="true" :lazy="true" :paginator="true" 
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport" 
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
						v-model:filters="dataTable.params.filters"
						v-model:selection="dataTable.selectedData" dataKey="id"
						:rowsPerPageOptions="dataTable.rows_option"
						:value="dataTable.data"
						:rows="dataTable.params.rows" 
						:totalRecords="dataTable.totalRecords" 
						:loading="dataTable.loading" 
						@page="loadData($event)" @sort="loadData($event)">
						<template #header>
							<div class="flex justify-content-between flex-column sm:flex-row">
								<div class="p-fluid">
									<MultiSelect v-model="dataTable.selectedColumns" :options="dataTable.columns"  optionValue="field" optionLabel="header" placeholder="Select Columns" display="chip" style="width: 20em"/>
								</div>
								<span class="p-input-icon-left mb-2">
									<i class="pi pi-search" />
									<InputText v-model="dataTable.params.filters['global'].value" @keydown.enter="loadData()" :placeholder="dataTable.global_placeholder" v-tooltip.top.focus="'Press Enter'" style="width: 100%"/>
								</span>
							</div>
						</template>
						<template #empty>
							<div class="p-text-center">No data found.</div>
						</template>
						<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
						<!-- Columns -->
						<Column v-for="(col, i) of dataTable.columns.filter(col => dataTable.selectedColumns.includes(col.field))" :field="col.field" :header="col.header" :sortable="col.sort" :showFilterMatchModes="false" :key="i">
							<template #body="{data}">
								<div v-if="col.default">{{ data[col.field] }}</div>
                                <div v-if="col.field == 'status'">{{ data.status }}</div>
								<div v-if="col.field == 'created_at'">{{ $filter_date(data.created_at) }}</div>
								<div v-if="col.field == 'action'">
									<Button v-if="$can('page-edit')" @click="$router.push(`/dashboard/page-edit/${data.id}`)" v-tooltip.top="'Edit'" icon="pi pi-pencil" class="p-button-rounded p-button-text p-button-primary mr-2" />
									<Button v-if="$can('page-delete')" @click="openDelete('single', data)" v-tooltip.top="'Hapus'" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
								</div>
							</template>

							<template v-if="col.filter" #filter="{filterModel,filterCallback}">
								<InputText v-if="col.default" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="column-filter" placeholder="Search" />
								<Dropdown v-else-if="col.field == 'status'" :options="relations.status" v-model="filterModel.value" @change="filterCallback()" optionValue="id" optionLabel="name" display="chip" :filter="true" placeholder="Select" />
							</template>
						</Column>
						<!-- End Columns -->
					</DataTable>

					<!-- Delete Confirmation -->
					<Dialog v-model:visible="dialog.deleteData" header="Confirmation" :style="{width: '350px'}" :modal="true" :dismissableMask="true" class="p-fluid">
						<div class="confirmation-content">
							<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
							<span>Do you want to delete this record?</span>
						</div>
						<template #footer>
							<Button label="No" icon="pi pi-times" @click="dialog.deleteData = false" class="p-button-text"/>
							<Button label="Yes" icon="pi pi-check" @click="deleteData" class="p-button-text" autofocus />
						</template>
					</Dialog>
				</template>
			</Card>

		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			title: 'Halaman',
			api: '/api/posts',
			relations: {
                status: null,
			},
			dataTable: {
				data: [],
				loading: false,
				global_placeholder: 'Judul',
				totalRecords: 0,
				rows_option: [10, 25, 50, 100],
				selectedData: [],
				columns: [
					{field: 'title', header: 'Judul', sort: true, filter: true, default: true},
					{field: 'status', header: 'Status', sort: true, filter: true,},
					{field: 'created_at', header: 'Dibuat Pada', sort: true, filter: false},
					{field: 'action', header: 'Action', sort: false, filter: false},
				],
				selectedColumns: ['title', 'status', 'created_at', 'action'],
				params: {
					filters: {
						global: {value: null},
						title: {value: null},
						status: {value: null},
					},
					rows: 10, //per_page
					page: 0,
					sortField: null,
					sortOrder: null,
				},
			},
			dialog: {
				formData: false,
				deleteData: false,
			},
			formDataTitle: null,
		}
	},
	methods: {
		loadData(event = null){
			this.$Progress.start();
			this.dataTable.loading = true;
			(event) ? this.dataTable.params = event : null;
			this.$httpAuth.get(`${this.api}/pages`, {
				params: {
					rows: this.dataTable.params.rows,
					sortField: this.dataTable.params.sortField,
					sortOrder: this.dataTable.params.sortOrder,
					filters: this.dataTable.params.filters,
					page: this.dataTable.params.page + 1,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.dataTable.loading = false;
				this.dataTable.data = response.data.data;
				this.dataTable.totalRecords = response.data.total;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.dataTable.loading = false;
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},

		getStatus(){
            this.$httpAuth.get(this.api + '/get/status')
            .then((response) => {
                this.relations.status = response.data;
            })
            .catch((error) => {
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },

		openDelete(mode = null, data = null){
			if(mode == 'single'){
				this.dataTable.selectedData = [];
				this.dataTable.selectedData.push(data);
			}
			this.dialog.deleteData = true;
		},
		deleteData() {
			this.$Progress.start();
			this.dialog.deleteData = false;
			const selectedId = this.dataTable.selectedData.map(value => value.id);
			this.$httpAuth.delete(this.api + '/delete', {
				params: {
					id: selectedId
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.loadData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
	},
	watch: {
		'dataTable.params.filters': function(){
			this.loadData();
		},
    },
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadData();
		this.getStatus();
	},
}
</script>